import Link from 'next/link';
import router from 'next/router';
import { useState } from 'react';

const Subsribe = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault(); // prevents default form submission
    // add your form submission logic here
    router.push('/request-demo?email=' + email); // Navigate to the signup page
  };

  return (
    <div className='subscribe-form m-auto fs-18' data-aos='fade-up'>
      <form
        onSubmit={handleSubmit}
        action='/request-demo'
        // method={'GET'} // Use GET method to pass email as a query parameter
        className='position-relative'
      >
        <input
          type='email'
          placeholder='Business email address'
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          value={email}
        />
        <button className='tran3s position-absolute fw-500'>Book a demo</button>
      </form>
      <p className='m0 pt-20 text-white fs-18'>
        Already a member?{' '}
        <Link href='/signin' className='fw-500 text-decoration-underline'>
          Sign in.
        </Link>
      </p>
    </div>
  );
};

export default Subsribe;
